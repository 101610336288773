<template>
  <b-form-group :label="label">
    <b-input-group>
      <b-form-input
        v-model="value"
        :type="inputType"
        :placeholder="placeHolder"
        @keyup="updateChange"
      />
      <b-input-group-append is-text>
        <feather-icon
          :icon="iconType"
          class="cursor-pointer"
          @click="togglePasswordVisibility"
        />
      </b-input-group-append>
    </b-input-group>
  </b-form-group>
</template>

<script>
import { BFormGroup, BInputGroup, BInputGroupAppend, BFormInput } from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
  },
  props: {
    placeHolder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    inputField: {
      type: String,
      default: '',
    },
    listener: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      inputType: 'password',
      iconType: 'EyeIcon',
      value: this.inputField,
    }
  },
  methods: {
    togglePasswordVisibility() {
      if (this.inputType === 'password') {
        this.inputType = 'text'
        this.iconType = 'EyeOffIcon'
      } else {
        this.inputType = 'password'
        this.iconType = 'EyeIcon'
      }
    },
    updateChange() {
      this.$emit('update-changes', {
        key: this.listener,
        value: this.value,
      })
    },
  },
}
</script>
